import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Accordion from "~/components/accordion/accordion";
import { Quote } from "~/components/TextBox";
import Button from "~/components/button/button";
import UnorderedList from "~/components/List/Unordered";
import Highlight from "~/components/highlight/highlight";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={9}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 9</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>Now that you have developed skills to assess website features, think back to the question about CBD that you considered at the start of this lesson:</p>

              <Quote>"Is CBD use to relieve chronic pain supported by science and medicine?"</Quote>

              <p>You will need 3 copies of the rubric—one for each of the CBD websites. If you added statements to the criteria column on the rubric you used before, make sure to add them to these copies of the rubric. Use the criteria on your rubric to score each of the CBD websites.</p>

              <Highlight>Only do the first category, "Lesson 1 Website Quality Initial Screen," now.</Highlight>

              <p><strong>Make sure you save all the rubrics.</strong> You will need them again in later lessons. You may want to save them with your name and the name or number of the CBD website. For example, myname_CBD1.</p>

              <Button
                action="secondary"
                className="my-2 lg:m-2"
                color="purple"
                href="/files/ms/lesson_1/MS__S_full_rubric-scl_final.docx"
                icon="download"
                title="Rubric lesson 1 section 9"
              >
                Rubric
              </Button>

              <div className="flex flex-col lg:flex-row">
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/article/cbd/cbd-now"
                  icon="external"
                  title="CBD Website 1"
                >
                  CBD Website 1
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/article/cbd/cbd-truth"
                  icon="external"
                  title="CBD Website 2"
                >
                  CBD Website 2
                </Button>
                <Button
                  action="secondary"
                  className="my-2 lg:m-2"
                  color="purple"
                  href="/article/cbd/cansativa-organics"
                  icon="external"
                  title="CBD Website 1"
                >
                  CBD Website 3
                </Button>
              </div>

              <p>Keep the following ideas in mind:</p>
              <UnorderedList>
                <li>Your scores do not need to match those of your teammates. The important part is that you can give a reason for your score.</li>
                <li>You can change your score at any time if you think the evidence supports a change.</li>
              </UnorderedList>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Now that students have learned about website features and have practiced using the rubric, they will go back to look at the 3 CBD websites.</p>
              <p>Allow time for students to score the sites using the rubric. At this stage, they are using only the category "Lesson 1 Website Quality Initial Screen." Students can work with their teammates to complete this step.</p>
              <p>Some students may try to infer things from the websites to justify higher scores. Encourage students to rely on evidence from the sites to make decisions. Also, encourage them to give specific reasons to justify the score they assign. For example, instead of saying that the site "does not include many of the criteria," have them write which criteria are not addressed or list reasons why the site scored well.</p>

              <Accordion headerText="Note to teachers" className="my-4" open={true}>
                Students will continue using the rubric in later lessons. Make sure that students save the rubrics for the CBD sites electronically for later use.
              </Accordion>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
